import React from 'react'

const Loader = () => {
  return (
    <div className='flex jc-c ai-c loader'>
        <svg width="288" height="42" viewBox="0 0 144 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.855 20.034V17.199L9.585 16.578V11.502C9.585 10.602 9.306 9.9 8.748 9.396C8.208 8.892 7.371 8.64 6.237 8.64C5.463 8.64 4.698 8.766 3.942 9.018C3.204 9.252 2.574 9.576 2.052 9.99L0.54 7.047C1.332 6.489 2.286 6.057 3.402 5.751C4.518 5.445 5.652 5.292 6.804 5.292C9.018 5.292 10.737 5.814 11.961 6.858C13.185 7.902 13.797 9.531 13.797 11.745V20.034H9.855ZM5.427 20.25C4.293 20.25 3.321 20.061 2.511 19.683C1.701 19.287 1.08 18.756 0.648 18.09C0.216 17.424 0 16.677 0 15.849C0 14.985 0.207 14.229 0.621 13.581C1.053 12.933 1.728 12.429 2.646 12.069C3.564 11.691 4.761 11.502 6.237 11.502H10.098V13.959H6.696C5.706 13.959 5.022 14.121 4.644 14.445C4.284 14.769 4.104 15.174 4.104 15.66C4.104 16.2 4.311 16.632 4.725 16.956C5.157 17.262 5.742 17.415 6.48 17.415C7.182 17.415 7.812 17.253 8.37 16.929C8.928 16.587 9.333 16.092 9.585 15.444L10.233 17.388C9.927 18.324 9.369 19.035 8.559 19.521C7.749 20.007 6.705 20.25 5.427 20.25Z" fill="white" className="svg-elem-1"></path>
            <path d="M17.6091 20.034V5.508H21.6321V9.612L21.0651 8.424C21.4971 7.398 22.1901 6.624 23.1441 6.102C24.0981 5.562 25.2591 5.292 26.6271 5.292V9.18C26.4471 9.162 26.2851 9.153 26.1411 9.153C25.9971 9.135 25.8441 9.126 25.6821 9.126C24.5301 9.126 23.5941 9.459 22.8741 10.125C22.1721 10.773 21.8211 11.79 21.8211 13.176V20.034H17.6091Z" fill="white" className="svg-elem-2"></path>
            <path d="M35.2367 20.25C33.8687 20.25 32.6357 19.944 31.5377 19.332C30.4397 18.702 29.5667 17.829 28.9187 16.713C28.2887 15.597 27.9737 14.283 27.9737 12.771C27.9737 11.241 28.2887 9.918 28.9187 8.802C29.5667 7.686 30.4397 6.822 31.5377 6.21C32.6357 5.598 33.8687 5.292 35.2367 5.292C36.4607 5.292 37.5317 5.562 38.4497 6.102C39.3677 6.642 40.0787 7.461 40.5827 8.559C41.0867 9.657 41.3387 11.061 41.3387 12.771C41.3387 14.463 41.0957 15.867 40.6097 16.983C40.1237 18.081 39.4217 18.9 38.5037 19.44C37.6037 19.98 36.5147 20.25 35.2367 20.25ZM35.9657 16.794C36.6497 16.794 37.2707 16.632 37.8287 16.308C38.3867 15.984 38.8277 15.525 39.1517 14.931C39.4937 14.319 39.6647 13.599 39.6647 12.771C39.6647 11.925 39.4937 11.205 39.1517 10.611C38.8277 10.017 38.3867 9.558 37.8287 9.234C37.2707 8.91 36.6497 8.748 35.9657 8.748C35.2637 8.748 34.6337 8.91 34.0757 9.234C33.5177 9.558 33.0677 10.017 32.7257 10.611C32.4017 11.205 32.2397 11.925 32.2397 12.771C32.2397 13.599 32.4017 14.319 32.7257 14.931C33.0677 15.525 33.5177 15.984 34.0757 16.308C34.6337 16.632 35.2637 16.794 35.9657 16.794ZM39.7727 20.034V17.064L39.8537 12.744L39.5837 8.451V0H43.7957V20.034H39.7727Z" fill="white" className="svg-elem-3"></path>
            <path d="M46.695 20.034V17.496L55.281 7.344L56.01 8.748H46.884V5.508H59.412V8.046L50.826 18.198L50.07 16.794H59.655V20.034H46.695Z" fill="white" className="svg-elem-4"></path>
            <path d="M69.1896 20.25C67.6236 20.25 66.2286 19.935 65.0046 19.305C63.7806 18.657 62.8176 17.766 62.1156 16.632C61.4316 15.498 61.0896 14.211 61.0896 12.771C61.0896 11.313 61.4316 10.026 62.1156 8.91C62.8176 7.776 63.7806 6.894 65.0046 6.264C66.2286 5.616 67.6236 5.292 69.1896 5.292C70.7196 5.292 72.0516 5.616 73.1856 6.264C74.3196 6.894 75.1566 7.803 75.6966 8.991L72.4296 10.746C72.0516 10.062 71.5746 9.558 70.9986 9.234C70.4406 8.91 69.8286 8.748 69.1626 8.748C68.4426 8.748 67.7946 8.91 67.2186 9.234C66.6426 9.558 66.1836 10.017 65.8416 10.611C65.5176 11.205 65.3556 11.925 65.3556 12.771C65.3556 13.617 65.5176 14.337 65.8416 14.931C66.1836 15.525 66.6426 15.984 67.2186 16.308C67.7946 16.632 68.4426 16.794 69.1626 16.794C69.8286 16.794 70.4406 16.641 70.9986 16.335C71.5746 16.011 72.0516 15.498 72.4296 14.796L75.6966 16.578C75.1566 17.748 74.3196 18.657 73.1856 19.305C72.0516 19.935 70.7196 20.25 69.1896 20.25Z" fill="white" className="svg-elem-5"></path>
            <path d="M84.8492 20.25C83.3012 20.25 81.9242 19.926 80.7182 19.278C79.5302 18.63 78.5852 17.748 77.8832 16.632C77.1992 15.498 76.8572 14.211 76.8572 12.771C76.8572 11.313 77.1992 10.026 77.8832 8.91C78.5852 7.776 79.5302 6.894 80.7182 6.264C81.9242 5.616 83.3012 5.292 84.8492 5.292C86.3792 5.292 87.7472 5.616 88.9532 6.264C90.1592 6.894 91.1042 7.767 91.7882 8.883C92.4722 9.999 92.8142 11.295 92.8142 12.771C92.8142 14.211 92.4722 15.498 91.7882 16.632C91.1042 17.748 90.1592 18.63 88.9532 19.278C87.7472 19.926 86.3792 20.25 84.8492 20.25ZM84.8492 16.794C85.5512 16.794 86.1812 16.632 86.7392 16.308C87.2972 15.984 87.7382 15.525 88.0622 14.931C88.3862 14.319 88.5482 13.599 88.5482 12.771C88.5482 11.925 88.3862 11.205 88.0622 10.611C87.7382 10.017 87.2972 9.558 86.7392 9.234C86.1812 8.91 85.5512 8.748 84.8492 8.748C84.1472 8.748 83.5172 8.91 82.9592 9.234C82.4012 9.558 81.9512 10.017 81.6092 10.611C81.2852 11.205 81.1232 11.925 81.1232 12.771C81.1232 13.599 81.2852 14.319 81.6092 14.931C81.9512 15.525 82.4012 15.984 82.9592 16.308C83.5172 16.632 84.1472 16.794 84.8492 16.794Z" fill="white" className="svg-elem-6"></path>
            <path d="M101.867 20.25C100.499 20.25 99.2656 19.944 98.1676 19.332C97.0696 18.702 96.1966 17.829 95.5486 16.713C94.9186 15.597 94.6036 14.283 94.6036 12.771C94.6036 11.241 94.9186 9.918 95.5486 8.802C96.1966 7.686 97.0696 6.822 98.1676 6.21C99.2656 5.598 100.499 5.292 101.867 5.292C103.091 5.292 104.162 5.562 105.08 6.102C105.998 6.642 106.709 7.461 107.213 8.559C107.717 9.657 107.969 11.061 107.969 12.771C107.969 14.463 107.726 15.867 107.24 16.983C106.754 18.081 106.052 18.9 105.134 19.44C104.234 19.98 103.145 20.25 101.867 20.25ZM102.596 16.794C103.28 16.794 103.901 16.632 104.459 16.308C105.017 15.984 105.458 15.525 105.782 14.931C106.124 14.319 106.295 13.599 106.295 12.771C106.295 11.925 106.124 11.205 105.782 10.611C105.458 10.017 105.017 9.558 104.459 9.234C103.901 8.91 103.28 8.748 102.596 8.748C101.894 8.748 101.264 8.91 100.706 9.234C100.148 9.558 99.6976 10.017 99.3556 10.611C99.0316 11.205 98.8696 11.925 98.8696 12.771C98.8696 13.599 99.0316 14.319 99.3556 14.931C99.6976 15.525 100.148 15.984 100.706 16.308C101.264 16.632 101.894 16.794 102.596 16.794ZM106.403 20.034V17.064L106.484 12.744L106.214 8.451V0H110.426V20.034H106.403Z" fill="white" className="svg-elem-7"></path>
            <path d="M121.533 20.25C119.877 20.25 118.419 19.926 117.159 19.278C115.917 18.63 114.954 17.748 114.27 16.632C113.586 15.498 113.244 14.211 113.244 12.771C113.244 11.313 113.577 10.026 114.243 8.91C114.927 7.776 115.854 6.894 117.024 6.264C118.194 5.616 119.517 5.292 120.993 5.292C122.415 5.292 123.693 5.598 124.827 6.21C125.979 6.804 126.888 7.668 127.554 8.802C128.22 9.918 128.553 11.259 128.553 12.825C128.553 12.987 128.544 13.176 128.526 13.392C128.508 13.59 128.49 13.779 128.472 13.959H116.673V11.502H126.258L124.638 12.231C124.638 11.475 124.485 10.818 124.179 10.26C123.873 9.702 123.45 9.27 122.91 8.964C122.37 8.64 121.74 8.478 121.02 8.478C120.3 8.478 119.661 8.64 119.103 8.964C118.563 9.27 118.14 9.711 117.834 10.287C117.528 10.845 117.375 11.511 117.375 12.285V12.933C117.375 13.725 117.546 14.427 117.888 15.039C118.248 15.633 118.743 16.092 119.373 16.416C120.021 16.722 120.777 16.875 121.641 16.875C122.415 16.875 123.09 16.758 123.666 16.524C124.26 16.29 124.8 15.939 125.286 15.471L127.527 17.901C126.861 18.657 126.024 19.242 125.016 19.656C124.008 20.052 122.847 20.25 121.533 20.25Z" fill="white" className="svg-elem-8"></path>
            <path d="M136.244 20.25C135.002 20.25 133.805 20.106 132.653 19.818C131.519 19.512 130.619 19.134 129.953 18.684L131.357 15.66C132.023 16.074 132.806 16.416 133.706 16.686C134.624 16.938 135.524 17.064 136.406 17.064C137.378 17.064 138.062 16.947 138.458 16.713C138.872 16.479 139.079 16.155 139.079 15.741C139.079 15.399 138.917 15.147 138.593 14.985C138.287 14.805 137.873 14.67 137.351 14.58C136.829 14.49 136.253 14.4 135.623 14.31C135.011 14.22 134.39 14.103 133.76 13.959C133.13 13.797 132.554 13.563 132.032 13.257C131.51 12.951 131.087 12.537 130.763 12.015C130.457 11.493 130.304 10.818 130.304 9.99C130.304 9.072 130.565 8.262 131.087 7.56C131.627 6.858 132.401 6.309 133.409 5.913C134.417 5.499 135.623 5.292 137.027 5.292C138.017 5.292 139.025 5.4 140.051 5.616C141.077 5.832 141.932 6.147 142.616 6.561L141.212 9.558C140.51 9.144 139.799 8.865 139.079 8.721C138.377 8.559 137.693 8.478 137.027 8.478C136.091 8.478 135.407 8.604 134.975 8.856C134.543 9.108 134.327 9.432 134.327 9.828C134.327 10.188 134.48 10.458 134.786 10.638C135.11 10.818 135.533 10.962 136.055 11.07C136.577 11.178 137.144 11.277 137.756 11.367C138.386 11.439 139.016 11.556 139.646 11.718C140.276 11.88 140.843 12.114 141.347 12.42C141.869 12.708 142.292 13.113 142.616 13.635C142.94 14.139 143.102 14.805 143.102 15.633C143.102 16.533 142.832 17.334 142.292 18.036C141.752 18.72 140.969 19.26 139.943 19.656C138.935 20.052 137.702 20.25 136.244 20.25Z" fill="white" className="svg-elem-9"></path>
        </svg>
    </div>
  )
}

export default Loader